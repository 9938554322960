
import './ElmCommon/bigo.css';
import './ElmCommon/main.css';
import { Elm } from './Main.elm';
import * as serviceWorker from './serviceWorker';


var app = Elm.Main.init({node: document.getElementById("root")});







// PROD:
var ws = new WebSocket("/api/cloud/ws-stream");
// LOCAL TESTING - TO dev-server:
//var ws = new WebSocket("wss://dev0.super-easy-sensors.com:443/api/cloud/ws-stream");
// LOCAL TESTING - TO LOCAL SERVER:
//var ws = new WebSocket("ws://localhost:7001/api/cloud/ws-stream");


ws.onmessage = function(message) {
    var data_in = JSON.parse(message.data);

    var data_msg = {
        "tag":"WebSocketMsgInData",
        "contents" : data_in
    }
    //console.log(message);
    app.ports.websocketIn.send(JSON.stringify(data_msg));
};

ws.onerror = function(message) {
     var data_msg = { "tag":"WebSocketMsgInOnError", "contents" : "" }
     console.log("index.js - WS-Error");
     app.ports.websocketIn.send(JSON.stringify(data_msg));
};

ws.onclose = function(message) {
     console.log("index.js - WS-OnClose");
     var data_msg = { "tag":"WebSocketMsgInOnClose", "contents" : "" }
     app.ports.websocketIn.send(JSON.stringify(data_msg));
};


app.ports.websocketOut.subscribe(function(msg) {
    console.log(msg);
    ws.send(msg);
});








// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
